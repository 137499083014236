export default {
  contacts: "Контакты",
  address: "Адрес",
  phone: "Телефон",
  askQuestion: "Задать вопрос",
  yourPhone: "Номер телефона",
  yourName: "Ваше имя",
  yourEmail: "Ваш Email",
  fio: "ФИО",
  interestedVacancy: "Желаемая должность",
  yourCV: "Прикрепить свое CV",
  requestComment: "Сопроводительное письмо",
  enterYourQuestion: "Введите ваш вопрос",
  send: "Отправить",
  contactUs: "связаться с нами",
  faqAnswers: "Ответы на популярные вопросы",
  language: "Язык",

  newsAndPromos: "Новости и статьи",
  allNews: "все",
  promos: "акции",
  companyNews: "Новости компании",
  smi: "сми о нас",
  headings: "Оглавление",
  youMayLike: "Может быть интересно",
  vacancy: "Вакансии",
  seeVacancy: "смотреть вакансии",
  noVacancy: "Не нашли подходящую вакансию?",
  vacancyDisclaimer:
    "Возможно, она скоро откроется! Оставьте вашу заявку и мы свяжемся с вами в ближайшее время.",

  cooperation: "Сотрудничество",
  companyName: "Название компании",
  yourOffer: "Ваше предложение",
  cooperationDisclaimer: "Заполните форму для того чтобы с нами связаться",

  aboutUs: "О нас",
  companyHistory: "История компании",
  philosophy: "Философия компании",
  advantages: "Преимущества продукции",

  productCatalog: "Каталог продукций",

  footerTitle: "Косметическая продукция мирового класса",
  sections: "Разделы",
  products: "Продукция",
  aboutCompany: "О компании",

  ratings: "оценок",
  specs: "Характеристики",
  allSpecs: "Все характеристики",
  description: "Описание",
  composition: "Состав",
  reviews: "Отзывы",
  productReviews: "Отзывы о товаре",
  byRating: "Показать по рейтингу",
  reviews2: "отзыва",
  writeReview: "оставить отзыв",
  writeReview2: "Оставить отзыв",
  allProducts: "вся продукция",
  recommend: "Рекомендуем",
  ourBrands: "наши бренды",
  ourBrands2: "Наши бренды",

  subscriptionToNews: "Подписка на новости",
  subscriptionDescription:
    "Подпишитесь и получайте экскулюзивные акции и новости.",
  toSubscript: "подписаться",
  enterSearchQuery: "Введите запрос для поиска",
  ourSeries: "Наши серии",
  ourCountries: "Страны поставок наших товаров",
  becomePartner: "стать партнером",
  readPromo: "читать",
  detailed: "подробнее",
  viewAllSeries: "Смотреть все серии",
  subscriptionSuccesfull: "Подписка на новости оформлена!",
  contactSuccess: "Мы скоро свяжемся с Вами!",
  clickForRate: "Нажмите для оценки",
  review: "Отзыв",
  reviewSent: "Отзыв отправлен на модерацию!",
  errorOccured: "Произошла ошибка!",
  fillForm: "Заполните форму, пожалуйста!",
  subscriptionCongrats:
    "Готово! Теперь мы будем ежедневно отправлять вам интересные предложения на вашу почту!",
  allQuestions: "Все вопросы",
  information: "Информация",
  policy: "Политика конфиденциальности",
  ads: "Разработка сайта Usoft",
  copyright: "Все права защищены",
  readOurNews: "Читайте наши статьи и новости",
  contactUs2: "Cвязаться с нами",
  downloadCatalog: "Скачать каталог",
  ourTelegram: "Наш телеграм-канал",
  telegramChannel: "телеграм канал",
};
