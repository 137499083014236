import uz from "~/locales/uz.js";
import ru from "~/locales/ru.js";
import en from "~/locales/en.js";
export default defineI18nConfig(() => ({
  legacy: false,
  locale: "ru",
  messages: {
    ru,
    uz,
    en,
  },
}));
