export default {
  contacts: "Contacts",
  address: "Address",
  phone: "Phone",
  askQuestion: "Ask a question",
  yourPhone: "Your phone number",
  yourName: "Your name",
  yourEmail: "Your Email",
  fio: "Full Name",
  interestedVacancy: "Desired position",
  yourCV: "Attach your CV",
  requestComment: "Cover letter",
  enterYourQuestion: "Enter your question",
  send: "Send",
  contactUs: "Contact us",
  faqAnswers: "Answers to popular questions",
  language: "Language",

  newsAndPromos: "News and promotions",
  allNews: "All",
  promos: "Promotions",
  companyNews: "Company news",
  smi: "Media about us",
  headings: "Headings",
  youMayLike: "You might be interested",
  vacancy: "Vacancies",
  seeVacancy: "View vacancies",
  noVacancy: "Didn't find a suitable vacancy?",
  vacancyDisclaimer:
    "It may open soon! Leave your application and we will contact you shortly.",

  cooperation: "Cooperation",
  companyName: "Company name",
  yourOffer: "Your offer",
  cooperationDisclaimer: "Fill out the form to contact us",

  aboutUs: "About us",
  companyHistory: "Company history",
  philosophy: "Company philosophy",
  advantages: "Product advantages",

  productCatalog: "Product catalog",

  footerTitle: "World-class cosmetic products",
  sections: "Sections",
  products: "Products",
  aboutCompany: "About the company",

  ratings: "Ratings",
  specs: "Specifications",
  allSpecs: "All specifications",
  description: "Description",
  composition: "Composition",
  reviews: "Reviews",
  productReviews: "Product reviews",
  byRating: "Show by rating",
  reviews2: "Reviews",
  writeReview: "Leave a review",
  writeReview2: "Leave a review",
  allProducts: "All products",
  recommend: "We recommend",
  ourBrands: "Our brands",
  ourBrands2: "Our brands",

  subscriptionToNews: "Subscribe to news",
  subscriptionDescription:
    "Subscribe and receive exclusive promotions and news.",
  toSubscript: "Subscribe",
  enterSearchQuery: "Enter a search query",
  ourSeries: "Our series",
  ourCountries: "Countries where our products are supplied",
  becomePartner: "Become a partner",
  readPromo: "Read",
  detailed: "More details",
  viewAllSeries: "View all series",
  subscriptionSuccesfull: "Subscription to news successfully completed!",
  contactSuccess: "We will contact you soon!",
  clickForRate: "Click to rate",
  review: "Review",
  reviewSent: "Review submitted for moderation!",
  errorOccured: "An error occurred!",
  fillForm: "Please fill out the form!",
  subscriptionCongrats:
    "Done! Now we will send interesting offers to your email daily!",
  allQuestions: "All questions",
  information: "Information",
  policy: "Privacy Policy",
  ads: "Website development by Usoft",
  copyright: "All rights reserved",
  readOurNews: "Read our news",
  contactUs2: "Contact us",
  downloadCatalog: "Download Catalog",
  ourTelegram: "Our Telegram channel",
  telegramChannel: "telegram channel",
};
