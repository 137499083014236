export default {
  contacts: "Kontaktlar",
  address: "Manzil",
  phone: "Telefon",
  askQuestion: "Savol berish",
  yourPhone: "Telefon raqamingiz",
  yourName: "Ismingiz",
  yourEmail: "Email manzilingiz",
  fio: "F.I.Sh",
  interestedVacancy: "Qiziqqan lavozim",
  yourCV: "O'zingizning CV faylingizni qo'shing",
  requestComment: "Hamroh xat",
  enterYourQuestion: "Savolingizni kiriting",
  send: "Yuborish",
  contactUs: "Biz bilan bog'lanish",
  faqAnswers: "Ommabop savollarga javoblar",
  language: "Til",

  newsAndPromos: "Yangiliklar va aksiyalar",
  allNews: "Batafsil",
  promos: "Aksiyalar",
  companyNews: "Kompaniya yangiliklari",
  smi: "Biz haqimizda ommaviy axborot vositalari",
  headings: "Sarlavhalar",
  youMayLike: "Sizga qiziqarli bo'lishi mumkin",
  vacancy: "Bo'sh ish o'rinlari",
  seeVacancy: "Bo'sh ish o'rinlarini ko'rish",
  noVacancy: "Mos bo'lgan ish o'rnini topmadingizmi?",
  vacancyDisclaimer:
    "Tez orada ochilishi mumkin! Arizangizni qoldiring va biz siz bilan tez orada bog'lanamiz.",

  cooperation: "Hamkorlik",
  companyName: "Kompaniya nomi",
  yourOffer: "Taklifingiz",
  cooperationDisclaimer: "Biz bilan bog'lanish uchun shaklni to'ldiring",

  aboutUs: "Biz haqimizda",
  companyHistory: "Kompaniya tarixi",
  philosophy: "Kompaniya falsafasi",
  advantages: "Mahsulot afzalliklari",

  productCatalog: "Mahsulotlar katalogi",

  footerTitle: "Dunyo miqyosidagi kosmetik mahsulotlar",
  sections: "Bo'limlar",
  products: "Mahsulotlar",
  aboutCompany: "Kompaniya haqida",

  ratings: "Baholashlar",
  specs: "Texnik xususiyatlar",
  allSpecs: "Barcha xususiyatlar",
  description: "Tavsif",
  composition: "Tarkibi",
  reviews: "Sharhlar",
  productReviews: "Mahsulot sharhlari",
  byRating: "Reyting bo'yicha ko'rsatish",
  reviews2: "Sharhlar",
  writeReview: "Sharh qoldirish",
  writeReview2: "Sharh qoldirish",
  allProducts: "Barcha mahsulotlar",
  recommend: "Tavsiya etamiz",
  ourBrands: "Bizning brendlarimiz",
  ourBrands2: "Bizning brendlarimiz",

  subscriptionToNews: "Yangiliklarga obuna",
  subscriptionDescription:
    "Obuna bo'ling va eksklyuziv aksiyalar va yangiliklarni oling.",
  toSubscript: "Obuna bo'lish",
  enterSearchQuery: "Qidiruv so'rovini kiriting",
  ourSeries: "Bizning seriyalarimiz",
  ourCountries: "Mahsulotlarimiz yetkazib beriladigan davlatlar",
  becomePartner: "Hamkor bo'lish",
  readPromo: "O'qish",
  detailed: "Batafsil",
  viewAllSeries: "Barcha seriyalarni ko'rish",
  subscriptionSuccesfull:
    "Yangiliklarga obuna muvaffaqiyatli amalga oshirildi!",
  contactSuccess: "Biz tez orada siz bilan bog'lanamiz!",
  clickForRate: "Baholash uchun bosing",
  review: "Sharh",
  reviewSent: "Sharh moderatsiyaga yuborildi!",
  errorOccured: "Xatolik yuz berdi!",
  fillForm: "Iltimos, shaklni to'ldiring!",
  subscriptionCongrats:
    "Tayyor! Endi biz har kuni qiziqarli takliflarni elektron pochtangizga yuboramiz!",
  allQuestions: "Barcha savollar",
  information: "Ma'lumot",
  policy: "Maxfiylik siyosati",
  ads: "Sayt ishlab chiqilgan - Usoft",
  copyright: "Barcha huquqlar himoyalangan",
  readOurNews: "Yangiliklarimizdan habardor bo'ling",
  contactUs2: "Biz bilan bog'lanish",
  downloadCatalog: "Bizning Katalog",
  ourTelegram: "Bizning Telegram kanalimiz",
  telegramChannel: "telegram kanalimiz",
};
